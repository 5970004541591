<template>
	<section>
		<div class="top-block">
			<h1>Контактная информация</h1>
			<lang-selector @get="(e) => (language = e)"></lang-selector>
		</div>
		<div class="content">
			<input-container
				ref="Address"
				label="Адрес"
				placeholder="Укажите адрес без города"
				:size="3"
				:value="whichVal('address')"
				@get="whichVal('address',$event)"
			/>
			<input-container
				ref="SidebarAddress"
				label="Адрес для сайд-бара"
				placeholder="Укажите адрес без города"
				:size="3"
				:value="whichVal('sidebarAddress')"
				@get="whichVal('sidebarAddress',$event)"
			/>
			<input-container
				ref="Phone"
				label="Номер телефона"
				placeholder="+7 XXX XXX XX XX"
				:value="contactsData.phone"
				@get="(e) => (contactsData.phone = e)"
			/>
			<input-container
				ref="Email"
				label="Email"
				placeholder="name@mail.ru"
				:value="contactsData.email"
				@get="(e) => (contactsData.email = e)"
			/>
			<input-container
				ref="WorkTime"
				label="Режим работы"
				placeholder="Пн-пт | 10:00-19:00"
				:size="3"
				:value="whichVal('workTime')"
				@get="whichVal('workTime',$event)"
			/>
			<photo-loader
				label="Изображение карты"
				minContent="1000 x 1000 "
				:images="whichImages('mapImage')"
				@photoId="(e) => whichImages('mapImage', e, true)"
			/>
			<input-container
				ref="MapUrl"
				label="Ссылка на карту"
				placeholder="https://goo.gl/maps/xxxxxx"
				:size="3"
				:value="contactsData.mapUrl"
				@get="(e) => (contactsData.mapUrl = e)"
			/>
			<input-container
				ref="Instagram"
				label="Ссылка на профиль Instagram"
				placeholder="https://instagram.com/xxxxxx"
				:value="contactsData.instagram"
				@get="(e) => (contactsData.instagram = e)"
			/>
			<input-container
				ref="Vk"
				label="Ссылка на профиль ВКонтакте"
				placeholder="https://vk.com/xxxxxx"
				:value="contactsData.vk"
				@get="(e) => (contactsData.vk = e)"
			/>
			<input-container
				ref="Facebook"
				label="Ссылка на профиль Facebook"
				placeholder="https://www.facebook.com/xxxxxxx"
				:value="contactsData.facebook"
				@get="(e) => (contactsData.facebook = e)"
			/>
			<input-container
				ref="Telegram"
				label="Аренда для корпоративных клиентов"
				placeholder="lofthall.ru"
				:value="contactsData.rentSite"
				@get="(e) => (contactsData.rentSite = e)"
			/>
			<input-container
				ref="Telegram"
				label="Для партнёров и артистов"
				placeholder="test@test.com"
				:value="contactsData.partnersEmail"
				@get="(e) => (contactsData.partnersEmail = e)"
			/>
			<input-container
				ref="Telegram"
				label="Тэг профиля Telegram"
				placeholder="@name"
				:value="contactsData.telegram"
				@get="(e) => (contactsData.telegram = e)"
			/>
			<div class="star-info">
				Пункты, отмеченные звездочкой (<span>*</span>) обязательны к
				заполнению.
			</div>
			<button @click="putContacts"
				class="add-btn"
				:class="{
					loading: loading,
				}"
			>
				{{loading ? "Загрузка" : "Сохранить"}}
			</button>
		</div>
	</section>
</template>

<script>
import InputContainer from "./components/InputContainer.vue";
import LangSelector from "./components/LangSelector.vue";
import PhotoLoader from "./components/PhotoLoader.vue";
import langMixin from "@/views/mixins/langMixin";
import { http } from "../components/AuthService/httpService";
export default {
	mixins: [langMixin],
	components: { InputContainer, PhotoLoader, LangSelector },
	data() {
		return {
			contactsData: {
				address: [
					{
						lang: "ru-RU",
						value: "",
					},
					{
						lang: "en-US",
						value: "",
					},
				],
				sidebarAddress: [
					{
						lang: "ru-RU",
						value: "",
					},
					{
						lang: "en-US",
						value: "",
					},
				],
				phone: "",
				email: "",
				workTime: [
					{
						lang: "ru-RU",
						value: "",
					},
					{
						lang: "en-US",
						value: "",
					},
				],
				mapImage: [],
				mapUrl: "",
				partnersEmail: "",
				rentSite: "",
				instagram: "",
				vk: "",
				facebook: "",
				telegram: "",
			},
			objName: "contactsData",
			loading: false
		};
	},
	methods: {
		getContacts() {
			http.get("/api/contacts").then((response) => {
				if (response.status < 400) {
					this.contactsData = response.data;
				}
			});
		},
		putContacts() {
			this.loading = true;
			const haveErros = (txt = null) => {
				let ch = true;
				for(let ref in this.$refs){
					ch = this.$refs[ref].checkReq(txt);
				}
				return ch;
			}
			if(!haveErros()){
				http.put("/api/contacts", this.contactsData).then((response) => {
					if (response.status >= 400) {
						for(let err in response.data.details){
							this.$refs[err].checkReq({name: err,txt: response.data.details[err]});
						}
					}
					this.loading = false;
				});
			}
		},
	},
	mounted() {
		this.getContacts();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";
.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(44px);
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}
.content {
	display: grid;
	grid-template-columns: repeat(2, min-content);
	gap: ptr(30px) ptr(20px);
}
.add-btn{
	width: ptr(146px);
}
</style>
