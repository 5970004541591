<template>
	<section>
		<div class="top-block">
			<h1>Загрузить фото в галерею</h1>
		</div>
		<div class="content">
			<photo-loader
				label="Изображение к тексту"
				minContent="1000 x 1000"
                :onlyOne="false"
				:images="images"
				@photoId="setImage"
			/>
			<div class="star-info">
				Пункты, отмеченные звездочкой (<span>*</span>) обязательны к
				заполнению.
			</div>
			<button @click="saveGallery"
				class="add-btn"
				:class="{
					loading: loading,
				}"
			>
				{{loading ? "Загрузка" : "Сохранить"}}
			</button>
		</div>
	</section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import PhotoLoader from "./components/PhotoLoader.vue";
export default {
	components: {
		PhotoLoader,
	},
    data(){
        return{
            images: [],
			loading: false
        }
    },
    methods:{
        setImage(e){
            if(e === null){
				this.images = [0];
				return;
			}
			else{
				this.images = e.map( e => e.id );
			}
		},
        saveGallery(){
			this.loading = true;
            const imgs = this.images.map( e => ({id: e, sortOrder: 0}));
            http.put("/api/gallery",{
                images: imgs
            })
			.then(()=>{
				this.loading = false;
			})
        },
        getGalleryImages(){
            http.get("/api/gallery")
            .then((response)=>{
                if(response.status < 400){
                    this.images = response.data.images;
                }
            })
        }
    },
    mounted(){
        this.getGalleryImages();
    }
}
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";
.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(44px);
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}
.content {
	display: grid;
	grid-template-columns: repeat(2, min-content);
	gap: ptr(30px) ptr(20px);
}
.add-btn{
	width: ptr(146px);
}
</style>