<template>
	<section
		@mousemove="moveEvent"
		@mouseup="ungrabEvent"
		@mouseleave="ungrabEvent"
	>
		<div class="top-block">
			<h1>Типы билетов</h1>
			<button @click="$router.push('/tickets-page/')">
				Добавить билет
			</button>
		</div>
		<div class="legend">
			<span></span>
			<div class="column">
				Название
				<img src="" alt="" />
			</div>
			<div class="column">
				Изменено
				<img src="" alt="" />
			</div>
		</div>
		<div class="table main-events" :class="{ moving: movingTarget.el }">
			<div
				v-for="(m, k) in models"
				:key="k"
				@mousemove="moveOtherEvent($event, k, 'models')"
				class="move-component"
			>
				<div
					@mousedown="grabEvent($event, k, 'models')"
					class="drag-icon"
				></div>
				<div class="artist-name">
					{{ m.name }}
				</div>
				<div class="changed-date">
					{{ parseDate(m.updatedAt) }}
				</div>
				<div tabindex="0" class="options">
					<div class="settings-component">
						<router-link :to="'/tickets-page/' + m.id" class="opt">
							Редактировать
						</router-link>
						<div @click="deleteModel(m.id)" class="opt">
							Удалить
						</div>
					</div>
					<img src="/static/icons/kebab-menu.svg" alt="" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import dragMixin from "@/views/mixins/dragMixin";
import { http } from "../components/AuthService/httpService";
export default {
	mixins: [dragMixin],
	data() {
		return {
			models: [],
			allModels: [],
		};
	},
	methods: {
		getModels() {
			http.get("/api/info-cards?cardType=Ticket&type=All").then(
				(response) => {
					this.models = response.data.models.map((e, k) => ({
						...e,
						sortOrder: k,
					}));
				}
			);
		},
		deleteModel(id) {
			http.delete("/api/info-cards/" + id).then((response) => {
				if (response.status < 400) {
					this.getModels();
				}
			});
		},
		saveSort() {
			const sortedMass = this.models.map((e) => ({
				id: e.id,
				sortOrder: e.sortOrder,
			}));
			http.put("/api/info-cards/sort", {
				entries: sortedMass,
			});
		},
	},
	mounted() {
		this.getModels();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/defaultPage.scss";

.legend,
.move-component {
	grid-template-columns: ptr(40px) auto ptr(200px) ptr(40px);
}
</style>
