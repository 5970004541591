<template>
	<section>
		<div class="top-block">
			<h1>Карточка о площадке</h1>
			<lang-selector
				v-if="modelData.id"
				@get="(e) => (language = e)"
			></lang-selector>
		</div>
		<div class="content">
			<input-container
				label="Название вкладки"
				:full="true"
				:value="whichVal('name')"
				@get="whichVal('name', $event)"
			/>
			<input-container
				label="Instagram"
				:required="false"
				:value="modelData.socials.instagram"
				@get="modelData.socials.instagram = $event"
			/>
			<input-container
				label="Facebook"
				:required="false"
				:value="modelData.socials.facebook"
				@get="modelData.socials.facebook = $event"
			/>
			<input-container
				label="Cсылка на сайт"
				:required="false"
				:size="3"
				:value="modelData.socials.site"
				@get="modelData.socials.site = $event"
			/>
			<input-container
				label="Текстовая часть контента"
				:textarea="true"
				:value="whichVal('description')"
				@get="whichVal('description', $event)"
			/>
			<photo-loader
				label="Изображение к тексту"
				minContent="1000 x 1000"
				:images="modelData.image"
				@photoId="setImage"
			/>
			<custom-checkbox
				label="Отображать на главной"
				:start="modelData.showOnMain"
				@get="(e) => modelData.showOnMain = e"
			/>
			<div class="star-info">
				Пункты, отмеченные звездочкой (<span>*</span>) обязательны к
				заполнению.
			</div>
			<button
				@click="modelData.id ? putModelData() : postModelData()"
				class="add-btn"
			>
				Сохранить
			</button>
		</div>
	</section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import InputContainer from "./components/InputContainer.vue";
import LangSelector from "./components/LangSelector.vue";
import PhotoLoader from "./components/PhotoLoader.vue";
import langMixin from "@/views/mixins/langMixin";
import CustomCheckbox from "./components/CustomCheckbox.vue";
export default {
	mixins: [langMixin],
	components: {
		InputContainer,
		PhotoLoader,
		LangSelector,
		CustomCheckbox,
	},
	data() {
		return {
			modelData: {
				id: "",
				name: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				description: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				socials: {
					instagram: "",
					facebook: "",
					vk: "",
					site: ""
				},
				image: null,
				showOnMain: false,
			},
			objName: "modelData",
		};
	},
	methods: {
		getModelData(id) {
			http.get("/api/info-cards/about/" + id).then((response) => {
				if (response.status < 400) {
					this.modelData = response.data;
				}
			});
		},
		setImage(e) {
			if (e === null) {
				this.modelData.image = null;
				return;
			}
			if (this.modelData.image === null) {
				this.modelData.image = {
					id: e.id,
					name: e.name,
				};
			} else {
				this.modelData.image.id = e.id;
			}
		},
		putModelData() {
			const obj = { ...this.modelData, imageId: this.modelData.image };
			obj.imageId = obj.imageId === null ? 0 : obj.imageId.id;
			delete obj.image;
			http.put("/api/info-cards/about/" + this.modelData.id, obj).then(
				(response) => {
					console.log(response);
				}
			);
		},
		postModelData() {
			const obj = { ...this.modelData, imageId: this.modelData.image.id };
			delete obj.image;
			http.post("/api/info-cards/about/", obj);
		},
	},
	mounted() {
		if (this.$route.params.id) {
			this.getModelData(this.$route.params.id);
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";
.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(44px);
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}
.content {
	display: grid;
	grid-template-columns: repeat(2, min-content);
	gap: ptr(30px) ptr(20px);
}
</style>
