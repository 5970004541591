<template>
	<div
		:ref="label"
		class="input-container"
		:class="{ full: full || size === 3 || textarea }"
	>
		<div class="label-info">
			{{ label }}
			<span v-if="required"
				>*
				<div class="error-text" v-if="errorText">{{ errorText }}</div></span
			>
			<div v-if="maxLength" class="max-l">
				{{ maskedInput.length }} / {{ maxLength }}
			</div>
		</div>
		<textarea
			v-if="textarea"
			:value="maskedInput"
			:class="{ error: error }"
			:maxlength="maxLength ? maxLength : null"
			:placeholder="placeholder"
			@input="setInput"
			@focus="error = false"
		></textarea>
		<input
			v-else
			v-mask="mask"
			:value="maskedInput"
			:maxlength="maxLength ? maxLength : null"
			@focus="error = false"
			@input="setInput"
			:class="`size-${size} ${error ? 'error' : ''}`"
			type="text"
			:placeholder="placeholder"
		/>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			default: "",
		},
		mask: {
			default: "",
		},
		required: {
			default: true,
		},
		maxLength: {
			default: 0,
		},
		placeholder: {
			default: "",
		},
		value: {
			default: "",
		},
		full: {
			default: false,
		},
		size: {
			default: 2,
		},
		textarea: {
			default: false,
		},
	},
	data() {
		return {
			errorText: "",
			inp: "",
			error: false,
			maskedInput: "",
		};
	},
	methods: {
		checkReq(errComponent) {
			if (!this.required || (!errComponent && this.maskedInput !== ""))
				return false;
			this.error = true;
			if (errComponent) {
				this.errorText = errComponent.txt;
			} else {
				this.errorText = "Вы не указали данные";
			}
			return true;
		},
		setInput(e) {
			this.maskedInput = this.maskInput(e.target.value);
			this.$emit("get", e.target.value);
		},
		maskInput(val) {
			let res = "";
			if (this.mask.length > 0) {
				for (let i = 0; i < val.length; i++) {
					if (this.mask[i] === "#") {
						res += val[i];
					} else {
						res += this.mask[i];
					}
				}
				return res;
			}
			return val;
		},
	},
	watch: {
		error(newVal) {
			if (!newVal) {
				this.errorText = "";
			}
		},
		value(newVal) {
			this.maskedInput = this.maskInput(newVal);
		},
	},
	mounted() {
		this.maskedInput = this.maskInput(this.value);
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.input-container {
	display: grid;
	gap: ptr(10px);
	// margin: 0 ptr(20px) ptr(30px) 0;
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	display: flex;
	span {
		margin-left: ptr(5px);
		color: $red;
		position: relative;
	}
}
.error-text {
	position: absolute;
	left: ptr(20px);
	top: 0;
	width: ptr(230px);
	background: white;
	border: 1px solid $red;
	border-radius: ptr(5px);
	padding: ptr(10px);
	pointer-events: none;
}
.max-l{
	margin-left: ptr(10px);
}
input,
textarea {
	border-radius: $brd;
	background: white;
	border: 1px solid $border-color;
	height: ptr(45px);
	padding: 0 0 0 ptr(20px);
	transition: $trs;
	font-size: ptr(16px);
	font-weight: 500;
	font-family: $main-font;
	box-sizing: border-box;
	&::placeholder {
		color: $subtext-color;
	}
	&:focus {
		outline: none;
		border: 1px solid $active-color;
	}
}
textarea {
	height: ptr(350px);
	resize: none;
	padding: ptr(20px) 0 0 ptr(20px);
}
.error {
	border: 1px solid $red;
}
.size-1 {
	width: ptr(220px);
}
.size-2 {
	width: ptr(340px);
}
.size-3 {
	width: ptr(700px);
}
.full {
	width: 100%;
	grid-column: span 2;
}
</style>
