import { render, staticRenderFns } from "./LangSelector.vue?vue&type=template&id=41e5ac70&scoped=true&"
import script from "./LangSelector.vue?vue&type=script&lang=js&"
export * from "./LangSelector.vue?vue&type=script&lang=js&"
import style0 from "./LangSelector.vue?vue&type=style&index=0&id=41e5ac70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e5ac70",
  null
  
)

export default component.exports