import Vue from "vue";
import VueRouter from "vue-router";
import Events from "../views/Events.vue";
import Model from '@/views/Model';
import Contacts from '@/views/Contacts';
import EventPage from '@/views/EventPage';
import ModelPage from '@/views/ModelPage';
import EnterPage from '@/views/EnterPage';
import Gallery from '@/views/Gallery';
import TicketsPage from "../views/TicketsPage.vue";
import Tickets from "../views/Tickets.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Events",
		component: Events,
	},
	{
		path: "/auth",
		name: "Authitication",
		component: EnterPage,
	},
	{
		path: "/event/:id?",
		name: "Event",
		component: EventPage,
	},
	{
		path: "/contacts",
		name: "Contacts",
		component: Contacts,
	},
	{
		path: "/gallery",
		name: "Gallery",
		component: Gallery,
	},
    {
		path: "/model",
		name: "Model",
		component: Model,
	},
	{
		path: "/model-page/:id?",
		name: "ModelPage",
		component: ModelPage,
	},
	{
		path: "/tickets",
		name: "Tickets",
		component: Tickets,
	},
	{
		path: "/tickets-page/:id?",
		name: "TicketsPage",
		component: TicketsPage,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
