<template>
	<section
		@mousemove="moveEvent"
		@mouseup="ungrabEvent"
		@mouseleave="ungrabEvent"
	>
		<div class="top-block">
			<h1>События</h1>
			<lang-selector @get="(e) => (language = e)" />
			<button @click="$router.push('/event')">Добавить событие</button>
		</div>
		<div class="no-events-container">
			<input-container
				label="Текст если событий больше нет"
				:size="3"
				:value="whichVal('title')"
				@get="whichVal('title', $event)"
				:required="false"
			/>
			<photo-loader
				label="Выбрать фото"
				minContent="1920 x 1080"
				:images="noEvents.file"
				@photoId="(e) => (noEvents.file = e.id)"
			/>
			<button @click="setNoEventsInfo" class="add-btn">
				Сохранить
			</button>
		</div>
		<div class="legend">
			<span></span>
			<div class="column">
				Название
				<img src="" alt="" />
			</div>
			<div class="column">
				Статус
				<img src="" alt="" />
			</div>
			<div class="column">
				Изменено
				<img src="" alt="" />
			</div>
		</div>
		<div class="table main-events" :class="{ moving: movingTarget.el }">
			<div
				v-for="(e, k) in mainEvents"
				:key="k"
				@mousemove="moveOtherEvent($event, k, 'mainEvents')"
				class="move-component"
			>
				<div
					@mousedown="grabEvent($event, k, 'mainEvents')"
					class="drag-icon"
				></div>
				<div class="artist-name">
					{{ e.name }}
				</div>
				<div class="status">
					{{ e.isPublished ? "Опубликовано" : "Скрыто" }}
				</div>
				<div class="changed-date">
					{{ parseDate(e.updatedAt) }}
				</div>
				<div tabindex="0" class="options">
					<div class="settings-component">
						<router-link :to="'/event/' + e.id" class="opt">
							Редактировать
						</router-link>
						<div @click="deleteEvent(e.id)" class="opt">
							Удалить
						</div>
						<div
							@click="changeStatus(e.id, e.isPublished)"
							class="opt"
						>
							{{ e.isPublished ? "Скрыть" : "Опубликовать" }}
						</div>
						<div @click="changeType(e.id, false)" class="opt">
							Убрать из главного
						</div>
					</div>
					<img src="/static/icons/kebab-menu.svg" alt="" />
				</div>
			</div>
		</div>
		<h2>
			Все мероприятия
		</h2>
		<div class="table" :class="{ moving: movingTarget.el }">
			<div
				v-for="(e, k) in allEvents"
				:key="k"
				@dragstart="grabEvent($event, k, 'allEvents')"
				@mousemove="moveOtherEvent($event, k, 'allEvents')"
				draggable="true"
				class="move-component"
			>
				<div @mouseup="ungrabEvent" class="drag-icon"></div>
				<div class="artist-name">
					{{ e.name }}
				</div>
				<div class="status">
					{{ e.isPublished ? "Опубликовано" : "Скрыто" }}
				</div>
				<div class="changed-date">
					{{ parseDate(e.updatedAt) }}
				</div>
				<div tabindex="0" class="options">
					<div class="settings-component">
						<router-link :to="'/event/' + e.id" class="opt">
							Редактировать
						</router-link>
						<div @click="deleteEvent(e.id)" class="opt">
							Удалить
						</div>
						<div
							@click="changeStatus(e.id, e.isPublished)"
							class="opt"
						>
							{{ e.isPublished ? "Скрыть" : "Опубликовать" }}
						</div>
						<div @click="changeType(e.id)" class="opt">
							Сделать главным
						</div>
					</div>
					<img src="/static/icons/kebab-menu.svg" alt="" />
				</div>
			</div>
		</div>
		<h2>
			Прошедшие мероприятия
		</h2>
		<div class="table">
			<div
				v-for="(e, k) in pastEvents"
				:key="k"
				class="move-component"
			>
				<div></div>
				<div class="artist-name">
					{{ e.name }}
				</div>
				<div class="status">
					{{ e.isPublished ? "Опубликовано" : "Скрыто" }}
				</div>
				<div class="changed-date">
					{{ parseDate(e.updatedAt) }}
				</div>
				<div tabindex="0" class="options">
					<div class="settings-component">
						<router-link :to="'/event/' + e.id" class="opt">
							Редактировать
						</router-link>
						<div @click="deleteEvent(e.id)" class="opt">
							Удалить
						</div>
						<div
							@click="changeStatus(e.id, e.isPublished)"
							class="opt"
						>
							{{ e.isPublished ? "Скрыть" : "Опубликовать" }}
						</div>
						<div @click="changeType(e.id)" class="opt">
							Сделать главным
						</div>
					</div>
					<img src="/static/icons/kebab-menu.svg" alt="" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import dragMixin from "@/views/mixins/dragMixin";
import { http } from "@/components/AuthService/httpService";
import InputContainer from "./components/InputContainer.vue";
import PhotoLoader from "./components/PhotoLoader.vue";
import langMixin from "@/views/mixins/langMixin";
import LangSelector from "@/views/components/LangSelector";
export default {
	components: { InputContainer, PhotoLoader, LangSelector },
	mixins: [dragMixin, langMixin],
	data() {
		return {
			noEvents: {
				title: [
					{
						lang: "ru-RU",
						value: "",
					},
					{
						lang: "en-US",
						value: "",
					},
				],
				file: null,
			},
			objName: "noEvents",
			loading: false,
			mainEvents: [],
			allEvents: [],
			pastEvents: []
		};
	},
	methods: {
		getAllEvents() {
			http.get("/api/events?type=main").then((response) => {
				if (response.status) {
					this.mainEvents = response.data.events.map((e, k) => ({
						...e,
						sortOrder: k,
					}));
				}
			});
			http.get("/api/events?type=ordinary").then((response) => {
				if (response.status) {
					this.allEvents = response.data.events.map((e, k) => ({
						...e,
						sortOrder: k,
					}));
				}
			});
			http.get("/api/events?type=passed").then((response) => {
				if (response.status) {
					this.pastEvents = response.data.events.map((e, k) => ({
						...e,
						sortOrder: k,
					}));
				}
			});
		},
		getNoEventsInfo() {
			http.get("/api/page-placeholders").then((response) => {
				this.noEvents = response.data;
			});
		},
		setNoEventsInfo() {
			this.loading = true;
			http.post("/api/page-placeholders",{
				...this.noEvents,
				fileId: this.noEvents.file
			})
			.then(()=>{
				this.loading = false;
			})
		},
		deleteEvent(id) {
			http.delete("/api/events/" + id).then((response) => {
				if (response.status < 400) {
					this.getAllEvents();
				}
			});
		},
		changeStatus(id, hide) {
			http.put(`/api/events/${id}/${hide ? "hide" : "publish"}`).then(
				(response) => {
					if (response.status < 400) {
						this.getAllEvents();
					}
				}
			);
		},
		saveSort(name) {
			http.put("/api/events/sort", {
				entries: this[name],
			}).then((response) => {
				if (response.status < 400) {
					console.log(response);
				}
			});
		},
		changeType(id, bool = true) {
			http.put(`/api/events/${id}/${bool ? "main" : "ordinary"}`).then(
				(response) => {
					if (response.status < 400) {
						this.getAllEvents();
					}
				}
			);
		},
	},
	mounted() {
		this.getAllEvents();
		this.getNoEventsInfo();
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/defaultPage.scss";

.no-events-container {
	display: grid;
	gap: ptr(30px);
	border-radius: ptr(20px);
	padding: ptr(40px) ptr(54px);
	border: 1px dashed $red;
	button {
		width: ptr(150px);
	}
}
.top-block {
	display: grid;
	grid-template-columns: min-content 1fr auto;
	align-items: center;
}
</style>
