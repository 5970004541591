<template>
	<section>
		<div class="top-block">
			<h1>Тип билета</h1>
			<lang-selector
				v-if="modelData.id"
				@get="(e) => (language = e)"
			></lang-selector>
		</div>
		<div class="content">
			<input-container
				label="Название билета"
				:full="true"
				:value="whichVal('name')"
				@get="whichVal('name', $event)"
			/>
			<input-container
				label="Cсылка на сайт"
				:required="false"
				:size="3"
				:value="modelData.site"
				@get="modelData.site = $event"
			/>
			<input-container
				label="Описание билета"
				:textarea="true"
				:value="whichVal('description')"
				@get="whichVal('description', $event)"
			/>
			<photo-loader
				label="Фотографии"
				minContent="1000 x 1000"
				:images="modelData.images"
				:maxSize="2"
				:onlyOne="false"
				@photoId="setImage"
			/>
			<div class="star-info">
				Пункты, отмеченные звездочкой (<span>*</span>) обязательны к
				заполнению.
			</div>
			<button
				@click="modelData.id ? putModelData() : postModelData()"
				class="add-btn"
			>
				Сохранить
			</button>
		</div>
	</section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import InputContainer from "./components/InputContainer.vue";
import LangSelector from "./components/LangSelector.vue";
import PhotoLoader from "./components/PhotoLoader.vue";
import langMixin from "@/views/mixins/langMixin";
export default {
	mixins: [langMixin],
	components: {
		InputContainer,
		PhotoLoader,
		LangSelector,
	},
	data() {
		return {
			modelData: {
				id: "",
				name: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				socials: {
					site: "",
					instagram: "",
					facebook: "",
				},
				description: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				images: [],
			},
			objName: "modelData",
		};
	},
	methods: {
		getModelData(id) {
			http.get("/api/info-cards/tickets/" + id).then((response) => {
				if (response.status < 400) {
					this.modelData = response.data;
				}
			});
		},
		setImage(e) {
			this.modelData.images = e.map((el) => el.id);
		},
		putModelData() {
			http.put(
				"/api/info-cards/tickets/" + this.modelData.id,
				this.modelData
			).then((response) => {
				console.log(response);
			});
		},
		postModelData() {
			http.post("/api/info-cards/tickets", this.modelData);
		},
	},
	mounted() {
		if (this.$route.params.id) {
			this.getModelData(this.$route.params.id);
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";
.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(44px);
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}
.content {
	display: grid;
	grid-template-columns: repeat(2, min-content);
	gap: ptr(30px) ptr(20px);
}
</style>
