export default {
	data() {
		return {
			language: "ru-RU",
		};
	},
	methods: {
		getLangId(val) {
			let lng = val.map((e) => e.lang);
			return lng.indexOf(this.language);
		},
		whichVal(name,set = null,subname,subId) {
			let val = this[this.objName][name];
			if(subId !== undefined){
				val = val[subId]
			}
			if(subname){
				val = val[subname];
			}
			const id = this.getLangId(val);
			if(set){
				val[id].value = set;
				return
			}
			return val[id].value;
		},
		whichImages(name, imgs, get = false) {
			const id = this.getLangId(this[this.objName][name]);
			if (get) {
				const isOne = imgs.length === undefined;
				if (id === -1){
                    if(isOne){
                        this[this.objName][name].push({
                            id: imgs.id,
                            lang: this.language,
                        });
                    }
                    else{
                        for(let i in imgs){
                            this[this.objName][name].push(i.id);
                        }
                    }
                }
				else{
					this[this.objName][name][id].id = isOne
						? imgs.id
						: imgs.map((e) => e.id);
                }
                return
			}
			if (id >= 0) return this[this.objName][name][id];
			else return null;
		},
	},
};
