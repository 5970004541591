<template>
	<div class="photo-loader">
		<div class="label-info">
			{{ label }} <span v-if="required" class="red">*</span>
			<span class="min-size">Мин размер {{ minContent }}px</span>
		</div>
		<div class="photos-container">
			<label>
				<input v-if="onlyOne" @change="addPhotos" type="file" />
				<input v-else @change="addPhotos" multiple type="file" />
				<div class="custom-file-get">
					Выбрать фото
				</div>
			</label>
			<div
				v-for="(p, k) in photos"
				:key="k"
				class="photo"
				:class="{
					loaded: p.loaded,
					drag: photoDrag === k,
					over: photoOver === k,
				}"
				draggable="true"
				@dragover.prevent
				@drop="drop(p.sort)"
				@dragleave="dragLeave()"
				@dragend="dragEnd"
				@dragenter="photoOver = p.sort"
				@dragstart="photoDrag = p.sort"
			>
				<img
					@click="slice(k)"
					class="close"
					src="/static/icons/close.svg"
					alt=""
				/>
				<img :src="p.url" class="content" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import { http } from "../../components/AuthService/httpService";
export default {
	props: {
		label: {
			default: "Название",
		},
		minContent: {
			default: "",
		},
		maxSize: {
			default: null,
		},
		required: {
			default: false,
		},
		images: {
			default: null,
		},
		onlyOne: {
			default: true,
		},
	},
	data() {
		return {
			photos: [],
			photoOver: null,
			photoDrag: null,
		};
	},
	methods: {
		addPhotos(e) {
			if (e.target.files[0]) {
				if (this.onlyOne) {
					this.photos = [
						{
							url: e.target.files[0],
							id: -1,
							loaded: false,
							sort: 0,
						},
					];
					this.loadImage(e.target.files[0], 0);
				} else {
					[...e.target.files].forEach((e, k) => {
						if (
							this.maxSize === null ||
							this.maxSize > this.photos.length
						) {
							this.loadImage(e, this.photos.length);
							this.photos.push({
								url: e,
								id: -1,
								loaded: false,
								sort: k,
							});
						}
					});
				}
			}
		},
		dragLeave() {
			this.photoOver = this.photoDrag;
		},
		dragEnd() {
			if (this.photoOver !== null) {
				this.clearDrag();
			}
		},
		clearDrag() {
			this.photoOver = null;
			this.photoDrag = null;
		},
		drop() {
			if (this.photoOver === this.photoDrag) {
				this.clearDrag();
				return;
			}
			// if (this.photoOver >= k) {
			// 	this.photos[this.photoOver].sort -= 1;
			// 	for (let i = this.photoOver + 1; i < this.photos.length; i++) {
			// 		console.log(this.photos[i].sort);
			// 		this.photos[i].sort += 1;
			// 	}
			// } else {
			// 	this.photos[this.photoOver].sort += 1;
			// 	for (let i = this.photoOver + 1; i > -1; i--) {
			// 		this.photos[i].sort -= 1;
			// 	}
			// }
			this.photos[this.photoDrag].sort = this.photoOver;
			this.photos[this.photoOver].sort = this.photoDrag;
			this.photos.sort((a, b) => a.sort - b.sort);
			this.clearDrag();
			this.emitIds();
		},
		emitIds() {
			if (this.onlyOne) {
				this.$emit(
					"photoId",
					this.photos[0] ? this.photos[0] : [{ id: 0 }]
				);
			} else {
				this.$emit("photoId", this.photos);
			}
		},
		slice(k) {
			this.photos.splice(k, 1);
			this.emitIds();
		},
		loadImage(file, k) {
			const formData = new FormData();
			formData.append("image", file);
			this.createImage(file).then((resFile) => {
				http.post("/api/images", formData).then((response) => {
					if (response.status < 400) {
						this.photos[k].loaded = true;
						this.photos[k].id = response.data.id;
						this.photos[k].url = resFile;
						this.emitIds();
					}
				});
			});
		},
		createImage(file) {
			const reader = new FileReader();
			return new Promise((resolve) => {
				reader.onload = (e) => {
					resolve(e.target.result);
				};
				reader.readAsDataURL(file);
			});
		},
		setPhotos(val) {
			if (this.onlyOne) {
				const v = val.length === undefined ? val : val[0];
				this.photos = [
					{
						id: v.id,
						url: v.url,
						loaded: true,
						sort: 0,
					},
				];
			} else {
				this.photos = val.map((e, k) => {
					return {
						id: e.id,
						url: e.url,
						loaded: true,
						sort: k,
					};
				});
			}
			this.emitIds();
		},
		checkVal(val) {
			if (val === null) {
				this.photos = [];
			} else if (typeof val === "string" || typeof val[0] === "string") {
				return;
			} else if (val.length === undefined || val.length > 0) {
				this.setPhotos(val);
			}
		},
	},
	watch: {
		images(newVal) {
			this.checkVal(newVal);
		},
	},
	mounted() {
		this.checkVal(this.images);
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.photo-loader {
	display: grid;
	gap: ptr(10px);
	grid-column: span 2;
	width: ptr(700px);
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
}
.min-size {
	margin-left: ptr(25px);
	color: $subtext-color;
}
.photos-container {
	display: flex;
	flex-wrap: wrap;
	gap: ptr(10px);
}
label {
	input {
		display: none;
	}
}
.custom-file-get {
	display: flex;
	align-items: center;
	justify-content: center;
	height: ptr(33px);
	height: ptr(80px);
	width: ptr(120px);
	border-radius: $brd;
	font-size: ptr(14px);
	color: $text-color;
	border: 1px solid $border-color;
	cursor: pointer;
}
.photo {
	background: rgb(0, 0, 0);
	border-radius: $brd;
	color: white;
	height: ptr(80px);
	width: ptr(120px);
	transition: $trs;
	overflow: hidden;
	font-weight: 500;
	position: relative;
	cursor: move;
	&:hover {
		.close {
			opacity: 1;
		}
	}
}
.drag {
	opacity: 0.5;
}
.over {
	box-shadow: 0 0 0 ptr(4px) $active-color;
}
.close {
	cursor: pointer;
	position: absolute;
	top: ptr(7px);
	right: ptr(7px);
	width: ptr(10px);
	z-index: 2;
	transition: $trs;
	opacity: 0;
}
.content {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
	pointer-events: none;
}
.hover-img {
	position: absolute;
	right: 0;
	top: 50%;
	opacity: 0;
	z-index: 2;
	pointer-events: none;
	background: $border-color;
	img {
		width: ptr(200px);
		object-fit: fill;
		margin: 0;
	}
	transition: $trs;
}
.loaded {
	background: #5c5c5c;
}
.red {
	color: $red;
	margin-left: ptr(3px);
}
</style>
