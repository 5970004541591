<template>
	<section>
		<div class="top-block">
			<h1>{{ id ? "Изменить событие" : "Добавить событие" }}</h1>
			<lang-selector
				v-if="id"
				@get="(e) => (language = e)"
			></lang-selector>
		</div>
		<article>
			<h2>
				Cобытие
			</h2>
			<input-container
				ref="name"
				label="Название события"
				:full="true"
				:value="whichVal('name')"
				@get="whichVal('name', $event)"
			/>
			<input-container
				ref="summary"
				label="Краткое описание события"
				:value="whichVal('summary')"
				:maxLength="100"
				:size="3"
				@get="whichVal('summary', $event)"
			/>
			<input-container
				ref="description"
				label="Полное описание события"
				:textarea="true"
				:value="whichVal('description')"
				@get="whichVal('description', $event)"
			/>
			<input-container
				ref="date"
				label="Дата"
				placeholder="01.01.2021"
				mask="##.##.####"
				:required="false"
				:value="parsedDate.date"
				:size="2"
				@get="(e) => (dateObj.date = e)"
			/>
			<input-container
				ref="date"
				label="Время"
				placeholder="01:01"
				mask="##:##"
				:required="false"
				:value="parsedDate.time"
				:size="2"
				@get="(e) => (dateObj.time = e)"
			/>
			<input-container
				ref="minPrice"
				label="Минимальная цена"
				:value="eventInfo.minPrice"
				:size="2"
				@get="(e) => (eventInfo.minPrice = e)"
			/>
			<input-container
				ref="minAge"
				placeholder="18"
				label="Возрастное ограничение"
				:value="eventInfo.minAge"
				:size="2"
				@get="(e) => (eventInfo.minAge = e)"
			/>
			<div class="tags-component">
				<div class="label-info">Тэги мероприятия<span>*</span></div>
				<div class="tags-container">
					<div v-for="(t, k) in eventInfo.tags" :key="k" class="tag">
						{{ t }}
						<img
							src="/static/icons/close.svg"
							alt=""
							@click="deleteTag(k)"
						/>
					</div>
					<input
						v-model="newTag"
						@keydown.enter="addTag"
						@keydown.backspace="
							newTag.length === 0
								? deleteTag(eventInfo.tags.length - 1, true)
								: null
						"
						type="text"
					/>
				</div>
			</div>
			<input-container
				ref="ticketWidget"
				label="Виджет билетного оператора"
				placeholder="Html код виджета"
				:value="eventInfo.ticketWidget"
				:textarea="true"
				@get="(e) => (eventInfo.ticketWidget = e)"
			/>
			<input-container
				ref="instagram"
				label="Ссылка на профиль Instagram"
				:required="false"
				placeholder="https://instagram.com/xxxxxx"
				:value="eventInfo.instagram"
				@get="(e) => (eventInfo.instagram = e)"
			/>
			<input-container
				ref="vk"
				label="Ссылка на профиль ВКонтакте"
				:required="false"
				placeholder="https://vk.com/xxxxxx"
				:value="eventInfo.vk"
				@get="(e) => (eventInfo.vk = e)"
			/>
			<input-container
				ref="facebook"
				label="Ссылка на профиль Facebook"
				:required="false"
				placeholder="https://www.facebook.com/xxxxxxx"
				:value="eventInfo.facebook"
				@get="(e) => (eventInfo.facebook = e)"
			/>
			<custom-checkbox
				label="Добавить стикер"
				:start="eventInfo.sticker !== null"
				@get="setSticker"
			/>
			<div v-if="eventInfo.sticker !== null" class="sticker-component">
				<div class="color-blocks">
					<div
						v-for="(c, k) in colors"
						:key="k"
						@click="eventInfo.sticker.color = c"
						class="color-block"
						:class="{ active: c === eventInfo.sticker.color }"
						:style="{ background: c }"
					></div>
				</div>
				<div
					@mousemove="moveSticker"
					@mouseup="stickerOn = false"
					@mouseleave="stickerOn = false"
					class="position-component"
				>
					<span>
						{{ whichVal("name") }}
					</span>
					<div
						@mousedown="stickerOn = true"
						class="sticker"
						:style="{
							top: eventInfo.sticker.offsetTop + 'px',
							left: eventInfo.sticker.offsetLeft + 'px',
							background: eventInfo.sticker.color,
						}"
					>
						<p>
							{{ whichVal("sticker", null, "message") }}
						</p>
					</div>
				</div>
			</div>
			<input-container
				ref="sticker"
				v-if="eventInfo.sticker !== null"
				label="Сообщение в стикере"
				placeholder="Впервые в Питере"
				:value="whichVal('sticker', null, 'message')"
				:required="false"
				@get="whichVal('sticker', $event, 'message')"
				:full="true"
			/>
			<input-container
				ref="partnersLabel"
				label="Название"
				:required="false"
				placeholder="Организаторы"
				:value="eventInfo.partnersLabel"
				@get="(e) => (eventInfo.partnersLabel = e)"
				:full="true"
			/>
			<photo-loader
				label="Логотипы"
				minContent="140 x 60 "
				:onlyOne="false"
				:images="eventInfo.partners"
				@photoId="(e) => (eventInfo.partners = e.map((e) => e.id))"
			/>
			<photo-loader
				label="Фон для страницы"
				minContent="1920 x 1080 "
				:images="eventInfo.poster"
				:required="true"
				@photoId="(e) => (eventInfo.poster = e.id)"
			/>
			<photo-loader
				label="Мобильный фон для страницы"
				minContent="1920 x 1080"
				:images="eventInfo.mobilePoster"
				:required="true"
				@photoId="(e) => (eventInfo.mobilePoster = e.id)"
			/>
			<input-container
				ref="url"
				label="Ссылка на страницу мероприятия"
				:value="eventInfo.url"
				@get="(e) => (eventInfo.url = e)"
				:size="3"
			/>
		</article>
		<article>
			<h2>
				Артисты
			</h2>
			<div
				v-for="(a, k) in eventInfo.artists"
				:key="k"
				class="mass-container"
			>
				<input-container
					ref="artists-name"
					label="Имя артиста"
					:value="whichVal('artists', null, 'name', k)"
					@get="whichVal('artists', $event, 'name', k)"
					:full="true"
				/>
				<input-container
					ref="artists-description"
					label="Об артисте"
					:value="whichVal('artists', null, 'description', k)"
					@get="whichVal('artists', $event, 'description', k)"
					:textarea="true"
				/>
				<photo-loader
					label="Фотография"
					minContent="1000 x 1000"
					:images="a.images"
					:required="true"
					@photoId="setImgToSubObject('artists', k, $event)"
				/>
			</div>
			<div class="control-btns">
				<button @click="addSubObject('artists')" class="add">
					Добавить артиста
				</button>
				<button @click="deleteSubObject('artists')" class="delete">
					Удалить
				</button>
			</div>
		</article>
		<article>
			<h2>
				Билеты
			</h2>
			<div class="simular-events">
				<div class="label-info">Выбрать заполненный билет</div>
				<div
					class="tags-container"
					tabindex="0"
					@click="openCards = true"
					@blur="openCards = false"
				>
					<div
						v-for="(t, k) in eventInfo.sharedTickets"
						:key="k"
						class="tag"
					>
						{{ t.name }}
						<img
							src="/static/icons/close.svg"
							alt=""
							@click="deleteElement('sharedTickets', k)"
						/>
					</div>
					<div v-if="openCards" class="options-container">
						<div
							v-for="(e, k) in sharedTickets"
							:key="k"
							class="opt"
							@click="choseElement('sharedTickets', e)"
						>
							{{ e.name }}
						</div>
					</div>
				</div>
			</div>
			<div
				v-for="(a, k) in eventInfo.tickets"
				:key="k"
				class="mass-container"
			>
				<input-container
					ref="tickets-name"
					label="Название билета"
					:value="whichVal('tickets', null, 'name', k)"
					@get="whichVal('tickets', $event, 'name', k)"
					:full="true"
				/>
				<input-container
					ref="tickets-description"
					label="О билете"
					:value="whichVal('tickets', null, 'description', k)"
					@get="whichVal('tickets', $event, 'description', k)"
					:textarea="true"
				/>
				<photo-loader
					label="Фотографии"
					minContent="1000 x 1000 "
					:images="a.images"
					:maxSize="2"
					:required="true"
					:onlyOne="false"
					@photoId="setImgToSubObject('tickets', k, $event)"
				/>
			</div>
			<div class="control-btns">
				<button @click="addSubObject('tickets')" class="add">
					Добавить билет
				</button>
				<button @click="deleteSubObject('tickets')" class="delete">
					Удалить
				</button>
			</div>
			<input-container
				ref="hallWidget"
				label="Виджет карты зала"
				:required="false"
				:value="eventInfo.hallWidget"
				@get="(e) => (eventInfo.hallWidget = e)"
				:textarea="true"
			/>
		</article>
		<article>
			<h2>
				Фото с выступлений
			</h2>
			<input-container
				ref="media"
				label="Видео-превью артиста"
				:required="false"
				placeholder="https://www.youtube.com/watch?v=XXXXXXXXXXX"
				:value="eventInfo.media.videoUrl"
				@get="(e) => (eventInfo.media.videoUrl = e)"
				:size="3"
			/>
			<photo-loader
				label="Фотографии"
				minContent="1000 x 1000 "
				:onlyOne="false"
				:images="eventInfo.media.images"
				@photoId="setImgToSubObject('media', undefined, $event)"
			/>
		</article>
		<article>
			<h2>
				Название категории
			</h2>
			<div class="simular-events">
				<div class="label-info">Выбрать заполненные категории</div>
				<div
					class="tags-container"
					tabindex="0"
					@click="openCards = true"
					@blur="openCards = false"
				>
					<div
						v-for="(t, k) in eventInfo.sharedCategories"
						:key="k"
						class="tag"
					>
						{{ t.name }}
						<img
							src="/static/icons/close.svg"
							alt=""
							@click="deleteElement('sharedCategories', k)"
						/>
					</div>
					<div v-if="openCards" class="options-container">
						<div
							v-for="(e, k) in sharedCategories"
							:key="k"
							class="opt"
							@click="choseElement('sharedCategories', e)"
						>
							{{ e.name }}
						</div>
					</div>
				</div>
			</div>
			<div
				v-for="(a, k) in eventInfo.categories"
				:key="k"
				class="mass-container"
			>
				<input-container
					ref="categories-name"
					label="Категория"
					:value="whichVal('categories', null, 'name', k)"
					@get="whichVal('categories', $event, 'name', k)"
				/>
				<!-- <custom-checkbox label="Добавить в карточки" :start="a.autoDelete"/> -->
				<input-container
					ref="categories-description"
					label="О категориии"
					:value="whichVal('categories', null, 'description', k)"
					@get="whichVal('categories', $event, 'description', k)"
					:textarea="true"
				/>
				<photo-loader
					label="Фотографии"
					minContent="1000 x 1000 "
					:images="a.image"
					:required="true"
					@photoId="setImgToSubObject('categories', k, $event)"
				/>
			</div>
			<div class="control-btns">
				<button @click="addCustomCategory()" class="add">
					Добавить категорию
				</button>
				<button @click="deleteSubObject('categories')" class="delete">
					Удалить
				</button>
			</div>
		</article>
		<article>
			<div class="simular-events">
				<div class="label-info">Похожие мероприятия</div>
				<div
					class="tags-container"
					tabindex="0"
					@click="openEvents = true"
					@blur="openEvents = false"
				>
					<div
						v-for="(t, k) in eventInfo.similarEvents"
						:key="k"
						class="tag"
					>
						{{ t.name }}
						<img
							src="/static/icons/close.svg"
							alt=""
							@click="deleteElement(k)"
						/>
					</div>
					<div v-if="openEvents" class="options-container">
						<div
							v-for="(e, k) in similarEvents"
							:key="k"
							class="opt"
							@click="choseElement('similarEvents', e)"
						>
							{{ e.name }}
						</div>
					</div>
				</div>
			</div>
			<custom-checkbox
				label="Добавить скрытым"
				:start="eventInfo.isHidden"
				@get="(e) => (eventInfo.isHidden = e)"
			/>
			<custom-checkbox
				label="Добавить в главные события"
				:start="eventInfo.isMain"
				@get="(e) => (eventInfo.isMain = e)"
			/>
			<div class="star-info">
				Пункты, отмеченные звездочкой (<span>*</span>) обязательны к
				заполнению.
			</div>
			<button
				@click="id ? saveData('put') : saveData('post')"
				class="add-btn"
				:class="{
					loading: loading,
				}"
			>
				{{
					loading
						? "Загрузка"
						: `${id ? "Изменить" : "Добавить"} событие`
				}}
			</button>
		</article>
	</section>
</template>

<script>
import CustomCheckbox from "./components/CustomCheckbox.vue";
import InputContainer from "./components/InputContainer.vue";
import LangSelector from "./components/LangSelector.vue";
import PhotoLoader from "./components/PhotoLoader.vue";
import langMixin from "@/views/mixins/langMixin";
import { http } from "../components/AuthService/httpService";
export default {
	mixins: [langMixin],
	components: { InputContainer, PhotoLoader, CustomCheckbox, LangSelector },
	data() {
		return {
			id: "",
			similarEvents: [],
			openEvents: false,
			sharedCategories: [],
			sharedTickets: [],
			openCards: false,
			addSticker: false,
			stickerOn: false,
			colors: [
				"#57ccc2e6",
				"#e5284ee6",
				"#4d39e7e6",
				"#afe528e6",
				"#6c16c1e6",
			],
			newTag: "",
			objName: "eventInfo",
			eventInfo: {
				name: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				summary: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				description: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				date: "",
				minPrice: "",
				minAge: "",
				tags: [],
				ticketWidget: "",
				instagram: "",
				vk: "",
				facebook: "",
				sticker: null,
				partnersLabel: "",
				partners: [],
				poster: null,
				mobilePoster: null,
				url: "",
				artists: [
					{
						name: [
							{
								lang: "ru-RU",
								value: "",
							},
						],
						description: [
							{
								lang: "ru-RU",
								value: "",
							},
						],
						images: [],
					},
				],
				tickets: [],
				sharedTickets: [],
				hallWidget: "",
				media: {
					videoUrl: "",
					images: [],
				},
				categories: [],
				sharedCategories: [],
				similarEvents: [],
				isHidden: false,
				isMain: false,
			},
			loading: false,
			dateObj: {
				date: "",
				time: "",
			},
		};
	},
	methods: {
		addTag() {
			this.eventInfo.tags.push(this.newTag);
			this.newTag = "";
		},
		deleteTag(k) {
			this.eventInfo.tags.splice(k, 1);
		},
		deleteElement(name, k) {
			this.eventInfo[name].splice(k, 1);
		},
		setSticker(del = false) {
			if (!del) {
				this.eventInfo.sticker = null;
			} else {
				this.eventInfo.sticker = {
					message: [
						{
							lang: "ru-RU",
							value: "",
						},
						{
							lang: "en-US",
							value: "",
						},
					],
					color: "",
					offsetTop: 0,
					offsetLeft: 0,
				};
			}
		},
		addSubObject(name) {
			this.eventInfo[name].push({
				name: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				description: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				images: [],
			});
		},
		addCustomCategory() {
			this.eventInfo.categories.push({
				name: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				description: [
					{
						lang: "ru-RU",
						value: "",
					},
				],
				imageId: null,
				autoDelete: true,
				showOnMain: false,
			});
		},
		deleteSubObject(name) {
			this.eventInfo[name].pop();
		},
		setImgToSubObject(name, key, ids) {
			ids = ids.length === undefined ? [ids.id] : ids.map((e) => e.id);
			if (key !== undefined) {
				if (name === "categories") {
					this.eventInfo[name][key].imageId = ids[0];
				} else {
					this.eventInfo[name][key].images = ids;
				}
			} else {
				this.eventInfo[name].images = ids;
			}
		},
		getSimilarEvents() {
			http.get("/api/events?type=all").then((response) => {
				if (response.status < 400) {
					this.similarEvents = response.data.events.filter(
						(e) => e.id !== parseInt(this.id)
					);
				}
			});
		},
		saveData(type) {
			this.loading = true;
			const haveErros = (txt = null) => {
				let ch = true;
				for (let ref in this.$refs) {
					if (this.$refs[ref] === undefined) {
						continue;
					} else if (this.$refs[ref].length === undefined)
						ch = this.$refs[ref].checkReq(txt);
					else
						for (let rff in this.$refs[ref])
							ch = this.$refs[ref][rff].checkReq(txt);
				}
				return ch;
			};
			const eventData = {
				...this.eventInfo,
				date: this.setDate(),
				similarEvents: this.eventInfo.similarEvents.map((e) => e.id),
				sharedCategories: this.eventInfo.sharedCategories.map(
					(e) => e.id
				),
				sharedTickets: this.eventInfo.sharedTickets.map(
					(e) => e.id
				),
			};
			if (!haveErros())
				http[type](
					"/api/events" + (type === "put" ? "/" + this.id : ""),
					eventData
				).then((response) => {
					if (response.status < 400) {
						this.getEvent();
					}
					this.loading = false;
				});
			else
				document.dispatchEvent(
					new CustomEvent("loadPosition", {
						detail: {
							message: "Вы не заполнили требуемые данные",
							type: "error",
						},
					})
				);
		},
		getEvent() {
			http.get("/api/events/" + this.id).then((response) => {
				if (response.status < 400) {
					this.eventInfo = response.data;
					this.dateObj = this.parsedDate;
					this.getSimilarEvents();
				}
			});
		},
		getCards() {
			http.get("/api/info-cards?cardType=Category&type=All").then((response) => {
				this.sharedCategories = response.data.models;
			});
		},
		getTickets() {
			http.get("/api/info-cards?cardType=Ticket&type=All").then((response) => {
				this.sharedTickets = response.data.models;
			});
		},
		choseElement(name, e) {
			const ids = this.eventInfo[name].map((e) => e.id);
			if (ids.indexOf(e.id) === -1) {
				this.eventInfo[name].push(e);
			}
		},
		moveSticker(e) {
			if (this.stickerOn) {
				this.eventInfo.sticker.offsetTop += e.movementY;
				this.eventInfo.sticker.offsetLeft += e.movementX;
			}
		},
		setDate() {
			const d = this.dateObj.date.split(".").map((e) => parseInt(e));
			const t = this.dateObj.time.split(":").map((e) => parseInt(e));
			const offset = new Date().getTimezoneOffset() / 60;
			return new Date(d[2], d[1] - 1, d[0], t[0] - offset, t[1]);
		},
	},
	computed: {
		parsedDate() {
			const addZero = (d) => {
				return d > 9 ? d : "0" + d;
			};
			const d = new Date(this.eventInfo.date);
			if (this.eventInfo.date === "") {
				return {
					date: "",
					time: "",
				};
			}
			return {
				date: `${addZero(d.getDate())}.${addZero(
					d.getMonth() + 1
				)}.${d.getFullYear()}`,
				time: `${addZero(d.getHours())}:${addZero(d.getMinutes())}`,
			};
		},
	},
	mounted() {
		this.getCards();
		this.getTickets();
		if (this.$route.params.id) {
			this.id = this.$route.params.id;
			this.getEvent();
		} else {
			this.getSimilarEvents();
		}
	},
};
</script>
<style lang="scss" scoped>
@import "../components/styles/config.scss";
.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(44px);
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}
article {
	display: flex;
	flex-wrap: wrap;
	max-width: ptr(700px);
	gap: ptr(30px) ptr(20px);
	margin-bottom: ptr(120px);
	h2 {
		font-size: ptr(30px);
		font-weight: 500;
		margin: 0;
		color: $text-color;
	}
}
.mass-container {
	display: flex;
	flex-wrap: wrap;
	gap: ptr(30px) ptr(20px);
}
.control-btns {
	display: flex;
	align-items: center;
}
.add {
	width: ptr(203px);
	height: ptr(46px);
	border-radius: ptr(10px);
	display: flex;
	align-items: center;
	justify-content: center;
	background: #68c9ff;
	color: white;
	font-weight: 500;
	font-size: ptr(14px);
	margin-right: ptr(20px);
	transition: $trs;
	&:hover {
		background: #3fbcff;
	}
}
.delete {
	height: ptr(46px);
	width: ptr(106px);
	color: $active-color;
	font-weight: 500;
	background: none;
	font-size: ptr(14px);
}
.tags-component {
	display: grid;
	gap: ptr(10px);
	width: 100%;
}
.tags-container {
	border: 1px solid $border-color;
	border-radius: $brd;
	width: 100%;
	box-sizing: border-box;
	height: ptr(45px);
	padding: 0 ptr(10px);
	display: flex;
	align-items: center;
	transition: $trs;
	background: white;
	&:focus-within {
		border: 1px solid $active-color;
	}
	input {
		background: none;
		border: none;
		height: 100%;
		width: 100%;
		font-size: ptr(14px);
		font-weight: 500;
		font-family: $main-font;
		color: black;
		&:focus {
			outline: none;
		}
	}
}
.label-info {
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
	}
}
.tag {
	padding: ptr(4px) ptr(10px) ptr(4px) ptr(10px);
	background: #68c9ff;
	border-radius: $brd;
	color: white;
	display: flex;
	font-size: ptr(14px);
	margin-right: ptr(20px);
	white-space: nowrap;
	img {
		margin-left: ptr(15px);
		width: ptr(7.5px);
		cursor: pointer;
	}
}
.sticker-component {
	width: ptr(1500px);
}
.position-component {
	border-radius: ptr(15px);
	border: 1px solid $border-color;
	padding: ptr(10px) ptr(15px);
	display: flex;
	align-items: center;
	position: relative;
	overflow: hidden;
	width: 100%;
	margin: ptr(20px) 0;
	span {
		text-transform: uppercase;
		font-size: ptr(140px);
		white-space: nowrap;
		font-family: "Gilroy", sans-serif;
		font-weight: 300;
	}
}
.color-blocks {
	display: grid;
	gap: ptr(8px);
	grid-auto-flow: column;
	grid-auto-columns: min-content;
}
.color-block {
	width: ptr(36px);
	height: ptr(36px);
	border-radius: $brd;
	border: ptr(2px) solid white;
	transition: $trs;
	cursor: pointer;
	box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0);
}
.active {
	box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0.3);
}
.sticker {
	position: absolute;
	border-radius: 50%;
	width: ptr(112px);
	height: ptr(112px);
	font-size: ptr(10px);
	background: #e5284d;
	color: white;
	transform: rotate(-20deg);
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	transition: background $trs;
	cursor: move;
	p {
		max-width: ptr(80px);
		text-align: center;
	}
}
.simular-events {
	display: grid;
	gap: ptr(10px);
	width: 100%;
	position: relative;
}
.options-container {
	position: absolute;
	z-index: 2;
	top: 100%;
	left: 0;
	width: calc(100% - 2rem / 16);
	border-radius: 0 0 $brd $brd;
	background: white;
	border-top: 1px solid transparent;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
}
.opt {
	padding: ptr(10px) ptr(20px);
	font-weight: 500;
	font-size: ptr(16px);
	cursor: pointer;
	transition: $trs;
	&:hover {
		background: $active-color;
		color: white;
	}
}
</style>
